.card-top-img {
  display: block;
  max-width: 100%;
  height: auto;
}
.image-container {
  width: 500px; /* Set the desired width for the container */
  height: 500px; /* Set the desired height for the container */
  overflow: hidden;
}

.image-container img {
  width: 100%; /* Make the image take up the full width of the container */
  height: auto; /* Allow the image to adjust its height while maintaining aspect ratio */
  object-fit: cover; /* Maintain aspect ratio and cover the entire container */
}
